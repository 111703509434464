import React from "react";
import "./hero.css";
import coachImage from "../../images/LifeCoachDawnRuiz.png";
import { FaPodcast } from "react-icons/fa";

const Hero = () => {
  return (
    <div className="hero-section" id="hero">
      <div className="hero-container">
        <div className="hero-content-container">
          <h2>
            Inspiring, uplifting, and encouraging women to break away from what
            no longer serves them
          </h2>
          <p>
            while building self-confidence, better communication, and
            relationships with themselves and others. Let me show you how!
          </p>
          <div className="hero-btn-container">
            <div className="hero-btn">
              <a href="#contact">
                <button>Book A Free Consultation</button>
              </a>
              <a
                href="https://open.spotify.com/show/3kH9pTWbMNrO33yUU0Xvh8?si=X98xH2kNS3qfNXsSd0nfSQ&nd=1&dlsi=d09e015bbf0e43d9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>
                  Listen to the Podcast <FaPodcast />
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="coach-image-container">
          <img src={coachImage} alt="Life Coach Dawn Ruiz" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
