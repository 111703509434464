import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { FaRegEnvelope } from "react-icons/fa";
import "./contact.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_zpdfr8h", "template_4mvepow", form.current, {
        publicKey: "czCScRK129Vz365JS",
      })
      .then(
        (result) => {
          console.log("SUCCESS! Message sent.", result);
          form.current.reset();
          alert("Message sent successfully");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className="contact-section-container">
      <div className="contact-section" id="contact">
        <div className="contact-title">
          <h2>Contact</h2>
        </div>
        <div className="contact-container">
          <div className="contact-content">
            <h3>Start the conversation</h3>
            <p>
              Have questions about my services or want to be a guest on the
              podcast? If so, contact me today.
            </p>

            <div className="contact-icons">
              <FaRegEnvelope color="#e8b4b8" /> info@lifecoachingwithdawn.com
            </div>
          </div>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <label></label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Enter your name"
                autoComplete="name"
                required
              />
              <label></label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your best email"
                autoComplete="email"
                required
              />
              <label></label>
              <input
                type="text"
                name="subject"
                id="subject"
                placeholder="Enter your subject"
                autoComplete="nsubject"
                required
              />
              <label></label>
              <textarea
                name="message"
                id="message"
                placeholder="Enter your message"
                autoComplete="off"
                required
              />
              <input type="submit" value="Submit" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
