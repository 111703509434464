import React from "react";
import "./about.css";

const About = () => {
  return (
    <div className="about-section" id="aboutme">
      <div className="about-container">
        <div className="about-content-title">
          <h2> About Me </h2>
        </div>
        <div className="about-content">
          <div className="about-content-container">
            <h3>Hi, my name is </h3>
            <br />
            <h4>Dawn Ruiz</h4>
            <br />
            <h5>Life Coach - Podcaster</h5>
            <br />
            <br />
            <p>
              As an IAP-certified life coach, I am dedicated to helping women
              break away from the struggles, doubts, and insecurities that keep
              them from living fulfilled and happy lives. I understand the
              challenges that come with making positive changes in our lives and
              it is my passion to provide guidance and support on your new
              journey. My mission is to empower women to unleash their inner
              strength, overcome adversity, and reach their utmost and highest
              potential.
            </p>
            <br />
            <p>Are you ready to take on the challenge?</p>
          </div>
          <div className="about-btn-container">
            <a href="#contact">
              <button>Book A Free 15-Min Consultation </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
