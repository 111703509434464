import React from "react";
import "./hero.css";

const HeroQs = () => {
  return (
    <div className="hero-question-container">
      <div className="hero-question">
        <h2>Ready to step-up and take control of your life?</h2>
      </div>
    </div>
  );
};

export default HeroQs;
