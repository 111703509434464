import React from "react";
import "./testimonials.css";
import testimonialPic1 from "../../images/testimonialPic1.png";
import testimonialPic2 from "../../images/testimonialPic2.png";
import { FaQuoteLeft } from "react-icons/fa";

const Testimonials = () => {
  return (
    <div className="testimonials-section" id="testimonials">
      <div className="testimonials-title">
        <h2>Testimonials</h2>
      </div>
      <div className="testimonials-container">
        <div className="testimonials-1">
          <div className="testimonials-1-card">
            <img src={testimonialPic2} alt="Sara M." />
            <h3>
              <FaQuoteLeft width="20px" color="#e8b4b8" /> Dawn is an incredible
              personal mentor who has the unique ability to inspire and motivate
              others. She has a wealth of knowledge and experience that she
              shares with her mentees, helping them to reach their goals in
              life. Her enthusiasm for learning, passion for teaching, and
              dedication to helping others make her an excellent mentor. She
              understands the importance of having a positive attitude and
              encourages her mentees to stay focused on their goals. With her
              guidance, mentees can reach their full potential and achieve
              success in whatever they set out to do.
            </h3>
            <p>Patty P.</p>
          </div>
        </div>
        <div className="testimonials-2">
          <div className="testimonials-2-card">
            <img src={testimonialPic1} alt="Ashley A." />
            <h3>
              <FaQuoteLeft width="20px" color="#e8b4b8" /> Dawn is an incredible
              individual. She is an excellent life coach who listens and always
              speaks with intent. Her friendship and guidance have helped me
              navigate through many different facets of my life. Forever a
              client.
            </h3>
            <p>Ashley A.</p>
          </div>
        </div>
        <div className="testimonials-3">
          <div className="testimonials-3-card">
            <img src={testimonialPic2} alt="Sara M." />
            <h3>
              <FaQuoteLeft width="20px" color="#e8b4b8" /> Dawn is truly one of
              the most inspiring people I have ever met. Her presence alone can
              shift the energy in a room, and her innate ability to connect with
              others in such an organic way never ceases to amaze me. I often
              tell Dawn she is the mentor I always hoped for but never thought I
              would find. She encourages me to overcome my fears, learn from my
              mistakes, and most importantly, has taught me to value the
              qualities that make me who I am. Having Dawn as a mentor has truly
              helped me become a better version of myself. She is an optimist
              who sees the potential in everyone, and she actively works to turn
              that potential into success.
            </h3>
            <p>Sara M.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
