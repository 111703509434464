import React, { useState } from "react";
import { Link } from "react-scroll";
import { FaInstagram, FaPodcast, FaBars, FaTimes } from "react-icons/fa";
import "./header.css";

const Navigation = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMenu = () => setClick(false);

  return (
    <div className="nav-section">
      <div className="nav-container">
        <div className="nav-logo">
          <a href="/">
            <h1>Life Coaching with Dawn</h1>
          </a>
        </div>

        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes className="icon" size={30} />
          ) : (
            <FaBars className="icon" size={30} />
          )}
        </div>

        <div className="nav-list">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item" id="base">
              <Link
                to="hero"
                spy={true}
                offset={-100}
                smooth={true}
                duration={500}
                onClick={closeMenu}
              >
                Home
              </Link>
            </li>
            <li className="nav-item" id="about">
              <Link
                to="aboutme"
                spy={true}
                offset={-100}
                smooth={true}
                duration={500}
                onClick={closeMenu}
              >
                About
              </Link>
            </li>
            <li className="nav-item" id="packages">
              <Link
                to="services"
                spy={true}
                offset={-100}
                smooth={true}
                duration={500}
                onClick={closeMenu}
              >
                Services
              </Link>
            </li>
            <li className="nav-item" id="feedback">
              <Link
                to="testimonials"
                spy={true}
                offset={-100}
                smooth={true}
                duration={500}
                onClick={closeMenu}
              >
                Testimonials
              </Link>
            </li>
            <li className="nav-item" id="radio">
              <a
                href="https://open.spotify.com/show/3kH9pTWbMNrO33yUU0Xvh8?si=X98xH2kNS3qfNXsSd0nfSQ&nd=1&dlsi=d09e015bbf0e43d9"
                target="_blank"
                rel="noopener noreferrer"
              >
                Podcast
              </a>
            </li>
            <li className="nav-item" id="consult">
              <Link
                to="contact"
                spy={true}
                offset={-100}
                smooth={true}
                duration={500}
                onClick={closeMenu}
              >
                Book a free consultation
              </Link>
            </li>
            <li className="nav-item" id="insta-icon">
              <a
                href="https://www.instagram.com/lifecoachingwithdawn/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </li>
            <li className="nav-item" id="podcast-icon">
              <a
                href="https://open.spotify.com/show/3kH9pTWbMNrO33yUU0Xvh8?si=X98xH2kNS3qfNXsSd0nfSQ&nd=1&dlsi=d09e015bbf0e43d9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaPodcast />
              </a>
            </li>
          </ul>
        </div>

        <div className="nav-icons-container">
          <div className="nav-icons">
            <a
              href="https://www.instagram.com/lifecoachingwithdawn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://open.spotify.com/show/3kH9pTWbMNrO33yUU0Xvh8?si=X98xH2kNS3qfNXsSd0nfSQ&nd=1&dlsi=d09e015bbf0e43d9"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaPodcast />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
