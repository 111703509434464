import React from "react";
import "./services.css";
import { FaCheckCircle } from "react-icons/fa";

const Services = () => {
  return (
    <div className="services-section" id="services">
      <div className="services-title">
        <h2>My Services</h2>
      </div>
      <div className="services-section-container">
        <div className="services-container">
          <div className="services-pack1">
            <h3>Starter Package</h3>
            <p>
              A one-on-one <b>30-minute</b> session where you'll get to:
            </p>
            <ul>
              <li>
                <FaCheckCircle size="18px" color="#E8B4B8" /> Challenge your
                thoughts and habits
              </li>
              <li>
                <FaCheckCircle size="18px" color="#E8B4B8" /> Identify what
                blocks you from taking action and moving forward
              </li>
              <li>
                <FaCheckCircle size="18px" color="#E8B4B8" /> Break your old
                patterns
              </li>
              <li>
                <FaCheckCircle size="18px" color="#E8B4B8" /> Create a clear
                path for change
              </li>
            </ul>
            <div className="services-btn-container">
              <a href="#contact">
                <button>Let's get started</button>
              </a>
            </div>
          </div>
          <div className="services-pack2">
            <h3>Premium Package</h3>
            <p>
              A one-on-one <b>60-minute</b> session where you'll get to:
            </p>
            <ul>
              <li>
                <FaCheckCircle size="18px" color="#E8B4B8" /> Challenge your
                thoughts and habits
              </li>
              <li>
                <FaCheckCircle size="18px" color="#E8B4B8" /> Identify what
                blocks you from taking action and moving forward
              </li>
              <li>
                <FaCheckCircle size="18px" color="#E8B4B8" /> Break your old
                patterns
              </li>
              <li>
                <FaCheckCircle size="18px" color="#E8B4B8" /> Create a clear
                path for change
              </li>
            </ul>
            <div className="services-btn-container">
              <a href="#contact">
                <button>Let's get started</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
