import React from "react";
import "./footer.css";
import { FaInstagram, FaPodcast } from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className="footer-section">
      <ScrollToTop smooth top="600" color="#e8b4b8" />
      <div className="footer-container">
        <div className="footer-icons-container">
          <div className="footer-icons">
            <a
              href="https://www.instagram.com/lifecoachingwithdawn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
          <div className="footer-icons">
            <a
              href="https://open.spotify.com/show/3kH9pTWbMNrO33yUU0Xvh8?si=X98xH2kNS3qfNXsSd0nfSQ&nd=1&dlsi=d09e015bbf0e43d9"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaPodcast />
            </a>
          </div>
        </div>
        <div className="copyright">
          <p><a href="https://docs.google.com/document/d/1q6aVaEuqQby8T4eWglSq1yVVH9KudXRCA2HjRUJJC4Q/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Privacy Policy </a>| <a href="https://docs.google.com/document/d/13NQJgF2740RMsl6x42ehK9fY0RXkpbHIkZordFLGzdY/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> | <a href="https://docs.google.com/document/d/1cEMIClnr6SIC042MsxlH_LeeCipXQw2ZkfTwY-LCvLQ/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Legal Disclaimer</a></p>
          <p>Life Coaching with Dawn &copy; {year} | All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
